@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* font-family: "Poppins", sans-serif; */
/* font-family: "Playfair Display", serif; */


/* Navbar CSS */
.navbar{
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  line-height: 26px;
  text-transform: uppercase;
  position: sticky;
  top: 0%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adjust shadow as needed */
 }

.nav-link{
  font-weight: 300 !important;
}

.navbar .active {
  border-bottom: 2px solid;
}

.dropdown-menu .active {
  border-bottom: none;
}

.navbar-brand{
  font-weight: 700;
  font-family: 'roboto', sans-serif;
  letter-spacing: 3px;
}

.footer{
  color: white;
  padding-top: 20px;
  padding-bottom: 5px;
  background: #151414;
}

.footer a{
  text-decoration: none;
  color: white;
  padding: 10px;
}

.footer a:hover{
  color: gray !important;
}

.pnf{
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title{
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading{
  font-weight: normal;
}

.pnf-btn{
  background-color: rgb(66, 62, 62);
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  text-decoration: none;
  padding:10px;
  margin-top: 10px;
}

.pnf-btn:hover{
  background-color: black;
  color: white;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.table-container {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 450px;
  border-radius: 5px;
}

.table-fixed-header thead th {
  position: sticky;
  top:0;
  z-index: 850;
}

.list-custom {
  background-color: red;
}

.link{
  text-decoration: none;
  font-weight: bold;
}

.toast-root{
  margin: 4%;
  background: #2c324b !important;
  color:#fff !important;
}

.floating-delete-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  font-size: 16px;
  border-radius: 5px;
}

.form-check-input{
  border: 1px solid;
}

.img-fluid{
  max-width: 70%;
  height: auto;
}

:root {  
  --background-color: #edeef0;
  --text-color: black;
}

[data-bs-theme='dark'] {
  --background-color: #1e1e1e;
  --text-color: white;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.grade-btn{
  background-color: white;
  color: black;
}

.grade-btn:hover{
  background-color: #139896;
}

.grade-link{
  color: black;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  font-size: large;
}

.grade-link:hover{
 color: white;
}

/* react-quill custom style */
.ql-container {
  border-radius: 0px 0px 8px 8px !important;
  overflow: visible;
  min-height: 112px;
}
.ql-toolbar {
  border-radius: 8px 8px 0px 0px !important; 
  background-color: #f4f4f4;
}

/* For desktops (screens larger than 768px) */
@media (min-width: 768px) {
  .desktop-img {
    height: 500px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

/* For mobile (screens smaller than 768px), image takes full width */
@media (max-width: 767px) {
  .desktop-img {
    width: 100% !important;
  }
}