.form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    flex-direction: column;
  }
  
  .form-container form {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 30px;
    border-radius: 2%;
  }
  
  .form-container form .title {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    font-family: "Playfair Display", serif;
  }
  
  .form-container form input {
    border: none;
    border-radius: 1;
  }
  
  .form-container form button {
    border: 1px solid black;
    border-radius: 0;
    background-color: #000;
    color: white;
    font-weight: bold;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 10px;
  }
  
  .form-container form button:hover {
    border: 1px solid black;
    color: white;
    background: #000000; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #434343,
      #000000
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #434343,
      #000000
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  .custom-profile{
    margin: 5%;
  }

  a{
    text-decoration: none;
  }